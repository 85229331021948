<template>
	<component :is="transitionType">
		<v-navigation-drawer
			v-if="showModal"
			v-model="showModal"
			fixed
			:height="drawerHeight"
			:width="drawerWidth"
			:mobile-breakpoint="600"
			right
			bottom
			temporary
			>
			<div
				:class="['side-modal-content', isMobile ? 'pa-6 mt-3' : 'pa-15 ma-5']"
				v-touch="{down : checkCloseOnDrag, start : dragStart, end : dragEnd}"
				ref="drawerContentTest"
			>
				<hr class="grip-bar">
					<!-- Section 1: Date Picker -->
					<ProductPackageSection
						:number="1"
						:title="$t('product.card1.title')">
						<ProductsDatePicker
							:productsSelected="productsSelected"
							@dateSelected="handleDateSelected"
							@calendarPricesStateChanged="updateStepState"
						/>
					</ProductPackageSection>
					<hr class="mt-10 mb-10 divider" v-if="showStep">
					<!-- Section 2: Time Pickers -->
					<ProductPackageSection
						v-if="showStep"
						ref="timeSlotSection"
						:number="2"
						:title="$t('product.card2.title')">
						<ProductsTimePicker
							class="mt-5"
							v-for="(product, index) in productsWithEventGroup"
							:key="index"
							:product="product"
							:products-selected="productsSelected"
							:date="selectedDate"
							@timeSelected="(slot) => handleTimeSelected(product, slot)"
						/>
					</ProductPackageSection>
					<hr class="mt-10 mb-10 divider" v-if="showStep">
					<!-- Section 3: Sub Total List -->
					<ProductPackageSection
						v-if="showStep"
						:number="3"
						:title="$t('product.card3.title')">
						<ProductsSubTotalList
							:products="productsFormat"
							:selectedTimeSlots="selectedTimeSlots"
							:displayPrice="hasAllRequiredEvents"/>
					</ProductPackageSection>

				<!-- Buttons -->
				<div class="d-flex justify-end pr-4">
					<v-btn class="cancel-button mr-3" outlined @click="closeModal">
						{{ $t('btn.cancel') }}
					</v-btn>
					<v-btn
						v-if="showStep"
						class="add-to-cart-button" outlined
						:disabled="!canAddToCart"
						:loading="addToCartLoading"
						@click="addToCart">
						{{ $t('btn.addToCart') }}
					</v-btn>
				</div>
			</div>
		</v-navigation-drawer>
	</component>
</template>

<script>
	import ProductPackageSection from './ProductPackageSection';
	import ProductsDatePicker from './ProductsDatePicker';
	import ProductsTimePicker from './ProductsTimePicker';
	import ProductsSubTotalList from './ProductsSubTotalList';
	import ProductV2Mixin from '@/mixins/ProductV2Mixin';
	import { WindowWidthMixin } from '@/mixins/ProductMixin'
	import { VExpandTransition, VExpandXTransition } from 'vuetify/lib/components'

	export default {
		name: 'ProductPackageSelector',
		mixins: [ProductV2Mixin, WindowWidthMixin],
		components: {
			ProductPackageSection,
			ProductsDatePicker,
			ProductsTimePicker,
			ProductsSubTotalList,
		},
		props: {
			productsSelected: {
				type: Map,
				required: true,
			},
			// Used in the ProductV2Mixin
			isCrossSell: {
				type: Boolean,
				default: false
			},
			// Used in the ProductV2Mixin
			isInCart: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				showModal: false,
				selectedTimeSlots: {},
				selectedDate : null,
				selectedDatePrices : null,
				addToCartLoading : false,
				productsFormat : [],
				showStep: true,
				dragStartDrawerTop : null,
				dragEndDrawerTop : null,
				transitionType : this.isMobile ? VExpandTransition : VExpandXTransition
			};
		},
		computed: {
			drawerHeight() {
				return this.isMobile ? '85%' : '100%'
			},
			drawerWidth(){
				let width = '100%'
				if(!this.isMobile){
					width = window.innerWidth <= 1600 ? '50%' : 800
				}
				return width
			},
		},
		watch : {
			showModal(newVal) {
				if(newVal) {
					this.productsFormat = this.formatArrayOfProduct()
				} else {
					this.productsFormat = []
					this.selectedTimeSlots = {}
					this.selectedDate = null
					this.selectedDatePrices= null
				}
			},
			selectedDate() {
				this.selectedTimeSlots = {}
			},
			isMobile(newVal) {
				this.transitionType = newVal ? VExpandTransition : VExpandXTransition
			}
		},
		methods: {
			handleTimeSelected(product, slot) {
				const productId = product.product?.data.id ?? product.data?.id;
				const parentComboProductId = product.product?.data.parent_combo_product_id ?? product.data?.parent_combo_product_id;

				this.$set(this.selectedTimeSlots, `${productId}-${parentComboProductId}` , slot);
				this.productsFormat = this.formatArrayOfProduct();
			},
			handleDateSelected(date, datePrices) {
				this.selectedDate = date;
				this.selectedDatePrices = datePrices;
				this.productsFormat = this.formatArrayOfProduct()

				// Autoscroll when a date has been selected
				if(this.selectedDate){
					this.$nextTick(() => {
						if (this.$refs.timeSlotSection) {
							// Ensure the DOM is ready before scrolling
							const timeSlotSection = this.$refs.timeSlotSection.$el;
							if (timeSlotSection) {
								timeSlotSection.scrollIntoView({ behavior: 'smooth' });
							}
						}
					});
				}
			},
			getProductDynamicPrice(productId, quantity, parentComboProductId) {
				return this.selectedTimeSlots[productId + '-' + parentComboProductId] ? (this.selectedTimeSlots[productId + '-' + parentComboProductId ]?.price * quantity).toFixed(2) : this.selectedDatePrices?.prices?.find(item => item.product_id === productId);
			},
			closeModal() {
				this.showModal = false;
			},
			openModal() {
				this.showModal = true;
			},
			updateStepState(hasPrices) {
				this.showStep = !hasPrices;
			},
			checkCloseOnDrag(e) {
				if(this.dragStartDrawerTop === this.dragEndDrawerTop) {
					this.closeModal()
				}
			},
			dragStart() {
				this.dragStartDrawerTop = this.getDrawerTopPosition()
			},
			dragEnd() {
				this.dragEndDrawerTop = this.getDrawerTopPosition()
			},
			getDrawerTopPosition() {
				const drawerContentRef = this.$refs['drawerContentTest']
				return drawerContentRef.getBoundingClientRect().top;
			}
		},
	};
</script>

<style lang="scss" scoped>
	.grip-bar {
		width: 40px;
		margin-bottom: 30px;
		margin-left: auto;
		margin-right: auto;
	}
	.divider {
		border-top: 1px solid $grey-light;
		opacity: 0.5;
	}
	.cancel-button { border: 1px solid $blackish; color: $blackish; background-color: transparent; }
	.add-to-cart-button.v-btn--disabled { border: 1px solid $grey-light; background-color: $grey-light; }

	// overwrite drawer max-height
	.v-navigation-drawer--bottom.v-navigation-drawer--is-mobile{
		max-height: none;
	}
</style>
