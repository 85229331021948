<template>
	<transition name="fade">
		<div
			v-if="isVisible"
			class="mx-3 cart-countdown rounded-pill px-3 py-1"
			v-window-focus="handleWindowFocus"
		>
			<v-icon v-if="this.$vuetify.breakpoint.smAndUp" color="#383838" small>mdi-cart-outline</v-icon>
			<span data-testid="cart-timer" class="font-weight-bold text-body-2">
				{{ timer }}
			</span>
		</div>
	</transition>
</template>

<script>
import { EComService, EventBus } from '@connectngo/sdk';
import globalVariables from "@/global";

const DEFAULT_TIME = 900 // 15 minutes

export default {
	name: 'CartTimer',
	data: () => ({
		time: 0,
		timerInterval: null
	}),
	computed: {
		timer(){
			let minutes = Math.floor(this.time/60).toString()
			if(minutes.length === 1) {
				minutes = `0${minutes}`
			}
			let seconds = Math.floor(this.time - minutes * 60).toString()
			if(seconds.length === 1) {
				seconds = `0${seconds}`
			}
			return `${minutes}:${seconds}`
		},
		isVisible(){
			return !!(this.itemCount > 0)
		},
		itemCount() {
			let itemCount = 0;
			this.$root.cart.data.items.forEach(element => {
				itemCount += element.data.quantity;
			});
			return itemCount
		},
	},
	methods: {
		async clearCart() {
			try {
				const cart = await new EComService().clearCart()
				EventBus.publish('CART_UPDATED', cart);
				globalVariables.cart = cart;
				this.$root.cart = cart;
			} catch (err) {
				this.$handleError(this, err)
			}
		},
		getTime() {
			const now = new Date()
			const lastCartUpdate = new Date(this.$root.cart.data.updated_at)
			const time = Math.ceil((now.getTime() - lastCartUpdate.getTime())/1000)
			return time <= DEFAULT_TIME ? DEFAULT_TIME - time : 0
		},
		setTimer(){
			this.time = this.getTime()
			this.timerInterval = setInterval(() => {
				if(this.time === 0) {
					this.clearCart()
					this.clearTimer()
				} else {
					this.time--
				}
			}, 1000);
		},
		clearTimer(){
			clearInterval(this.timerInterval);
		},
		updateTimer(count){
			// Clear existing timer interval
			this.clearTimer()
				if(!count) {
					// Set time to 0 if cart is empty
					this.time = 0
				} else {
					this.setTimer()
				}
		},
		handleWindowFocus(){
			// Update timer every time the app has focus to avoid timer issues when the app is in the background.
			this.updateTimer(this.itemCount)
		},
	},
	watch: {
		itemCount: {
			immediate: true,
			handler(count) {
				this.updateTimer(count)
			},
		},
	}
}
</script>

<style scoped lang="scss">
	.cart-countdown{
		width: 82px;
		background-color: #ffffff;
	}

	.fade-leave-active {
		transition: opacity .5s;
	}

	.fade-leave-to {
		opacity: 0;
	}
</style>
